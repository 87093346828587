import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({
  description, lang, meta, title, imgPath, imgAlt,
}) => (
  <Helmet
    htmlAttributes={{ lang }}
    title={title}
    meta={[
      {
        name: 'description',
        content: description,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'og:image',
        content: process.env.SITE_DOMAIN + imgPath,
      },
      {
        property: 'og:image:alt',
        content: imgAlt,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:description',
        content: description,
      },
      {
        name: 'twitter:image',
        content: process.env.SITE_DOMAIN + imgPath,
      },
      {
        name: 'twitter:image:alt',
        content: imgAlt,
      },
    ].concat(meta)}
  />
);

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  imgPath: PropTypes.string,
  imgAlt: PropTypes.string,
};

export default SEO;
